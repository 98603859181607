import React from "react";
import { NavLink } from "react-router-dom";
import LockIcon from '@mui/icons-material/Lock';
import Telemidia from "../../assets/logo.png";

const NavBar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
      <div className="container">
        <NavLink exact className="navbar-brand" to="/" aria-hidden="true">
          <img src={Telemidia} widht='75' height="70" alt="Telemidia - Maranhão" loading="lazy" />
        </NavLink>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbar-content"
          aria-controls="navbar-content"
          aria-expanded="false"
          aria-label="Toggle Navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>


        <div className="collapse navbar-collapse" id="navbar-content">
          <div className="navbar-nav">
            <NavLink
              exact
              className="nav-link"
              activeClassName="active"
              aria-current="page"
              to="/"
            >
              Página Inicial
            </NavLink>

            <NavLink
              className="nav-link"
              activeClassName="active"
              to="/partners"
            >
              Parceiros
            </NavLink>

            <NavLink
              className="nav-link"
              activeClassName="active"
              to="/peoples"
            >
              Integrantes
            </NavLink>

            {/* <NavLink
              className="nav-link"
              activeClassName="active"
              to="/publications"
            >
              Publicações
            </NavLink> */}

            <NavLink
              className="nav-link"
              activeClassName="active"
              to="/projects"
            >
              Projetos
            </NavLink>

            <NavLink
              className="nav-link"
              activeClassName="active"
              to="/contatos"
            >
              Contato
            </NavLink>

             { <NavLink className="nav-link" activeClassName="active" to="/admin/login">
              <span style={{ display: 'flex', alignItems: 'center', color: '#000' }}>
                <LockIcon style={{ marginRight: 4 }} />
                Acesso interno
              </span>
            </NavLink> }

          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
