import { createMuiTheme } from "@material-ui/core"

const defaultTheme = createMuiTheme()
const { breakpoints } = defaultTheme

export default createMuiTheme({ 
    ...defaultTheme,
    palette: {
        ///
    },
    typography: {
        lineHeight: 1.2
    },
    overrides: {
        MuiTypography: {
            body1:{
                fontSize: "0.85rem",
                [breakpoints.down(330)]: {
                    fontSize: '0.7rem'
                },
                [breakpoints.up("sm")]: {
                    fontSize: "0.95rem"
                },
                [breakpoints.up("md")]: {
                    fontSize: "1rem"
                },
                [breakpoints.up("lg")]: {
                    fontSize: "1.1"
                },
                [breakpoints.up("xl")]: {
                    fontSize: "1.3"
                },
            },
     
            h1: {
                fontSize: "3.5rem",
                [breakpoints.up("sm")]: {
                    fontSize: "3.5rem",
                },
                [breakpoints.up("md")]: {
                    fontSize: "3rem"
                },
                [breakpoints.up("lg")]: {
                    fontSize: "3rem"
                },
                [breakpoints.up("xl")]: {
                    fontSize: "7rem"
                }
            },
            h2: {
                fontSize: "3rem",
                [breakpoints.up("sm")]: {
                    fontSize: "3rem"
                },
                [breakpoints.up("md")]: {
                    fontSize: "2.75rem"
                },
                [breakpoints.up("lg")]: {
                    fontSize: "2.75rem"
                },
                [breakpoints.up("xl")]: {
                    fontSize: "6.75rem"
                }
            },
            h3: {
                fontSize: "2.5rem",
                [breakpoints.up("sm")]: {
                    fontSize: "2.75rem"
                },
                [breakpoints.up("md")]: {
                    fontSize: "2.5rem"
                },
                [breakpoints.up("lg")]: {
                    fontSize: "2.5rem"
                },
                [breakpoints.up("xl")]: {
                    fontSize: "6.5rem"
                }
            },
            h4: {
                fontSize: "2rem",
                [breakpoints.up("sm")]: {
                    fontSize: "2.5rem"
                },
                [breakpoints.up("md")]: {
                    fontSize: "2.25rem"
                },
                [breakpoints.up("lg")]: {
                    fontSize: "2.25rem"
                },
                [breakpoints.up("xl")]: {
                    fontSize: "6.25rem"
                }
            },
            h5: {
                fontSize: "2.25rem",
                [breakpoints.up("sm")]: {
                    fontSize: "2.35rem"
                },
                [breakpoints.up("md")]: {
                    fontSize: "2.5rem"
                },
                [breakpoints.up("lg")]: {
                    fontSize: "2.55rem"
                },
                [breakpoints.up("xl")]: {
                    fontSize: "3.45rem"
                }
            },
            h6: {
                fontSize: "1rem",
                [breakpoints.up("sm")]: {
                    fontSize: "1.5rem"
                },
                [breakpoints.up("md")]: {
                    fontSize: "1.75rem"
                },
                [breakpoints.up("lg")]: {
                    fontSize: "1.95rem"
                },
                [breakpoints.up("xl")]: {
                    fontSize: "2.85rem"
                }
            }
        }
    }
})



