import React, { Suspense, lazy } from "react";
import { HashRouter, Switch, Route, Redirect } from "react-router-dom";
import styled from "styled-components";

import { NavBar, Footer } from "./components";
import { isAuthenticated } from "./services/auth";

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Main = styled.div`
  flex-grow: 1;
  ${'' /* background:
		linear-gradient(-135deg, rgb(34, 76, 152) 10%, transparent),
		repeating-linear-gradient(45deg, rgba(34, 76, 152, 1) 0%, rgba(31, 48, 94, 0.6) 5%, transparent 5%, transparent 10%),
		repeating-linear-gradient(-45deg, rgba(34, 76, 152, 0.4) 0%, rgba(31, 48, 94, 0.5) 5%, transparent 5%, transparent 10%);
  background-color: rgba(34, 76, 152, 0.25);
  .display-4 {
    text-shadow: 0px 0px 5px #123456; */}
  }
`;

const PrivateRoute = ({ component: Component, ...params }) => (
  <Route {...params} render={props => (
    isAuthenticated() ? (
      <Component {...props} />
    ) : (
      <Redirect to={{ pathname: "/", state: { from: params.location } }} />
    )
  )} />
);

//Loading Pages
const Home = lazy(() => import("./views/Home"));
const Partners = lazy(() => import("./views/Partners"));
const Peoples = lazy(() => import("./views/Peoples"));
const Projects = lazy(() => import("./views/Projects"));
const Contatos = lazy(() => import("./views/Contact"));
const Projects_op2 = lazy(() => import("./views/Projects_op2"));
const Posts = lazy(() => import("./views/Posts"))

const AdminLogin = lazy(() => import("./views/Admin/Auth/Login"));
const AdminHome = lazy(() => import("./views/Admin/Home"));

const AdminUsers = lazy(() => import("./views/Admin/Users"));
const AdminNewUser = lazy(() => import("./views/Admin/Users/New"));

const AdminPublications = lazy(() => import("./views/Admin/Publications"));
const AdminNewPublication = lazy(() => import("./views/Admin/Publications/NewPublications"));

const AdminPeoples = lazy(() => import("./views/Admin/Peoples"));
const AdminNewPeople = lazy(() => import("./views/Admin/Peoples/NewPeople"));
const AdminNewPeopleCategory = lazy(() => import("./views/Admin/Peoples/NewCategory"));

const AdminPartners = lazy(() => import("./views/Admin/Partners"));
const AdminNewPartner = lazy(() => import("./views/Admin/Partners/NewPartner"));
const AdminNewPartnerCategory = lazy(() => import("./views/Admin/Partners/NewCategory"));

const AdminMessages = lazy(() => import("./views/Admin/Messages"))

export default () => (
  <HashRouter>
    <Suspense fallback={<div />}>
      <Wrapper>
        <NavBar />
        <Main>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/partners" component={Partners} />
            <Route path="/peoples" component={Peoples} />
            <Route path="/publications" component={Home} />
            <Route path="/projects" component={Projects} />
            <Route path="/contatos" component={Contatos} />
            <Route path="/projects_op2" component={Projects_op2} />
            <Route path="/posts/:id" component={Posts} />

            <Route path="/admin/login" component={AdminLogin} />
            <PrivateRoute exact path="/admin/" component={AdminHome} />

            <PrivateRoute exact path="/admin/users" component={AdminUsers} />
            <PrivateRoute path="/admin/users/new/:userId?" component={AdminNewUser} />

            <PrivateRoute exact path="/admin/publications" component={AdminPublications} />
            <PrivateRoute path="/admin/publications/new/:publicationId?" component={AdminNewPublication} />

            <PrivateRoute exact path="/admin/peoples" component={AdminPeoples} />
            <PrivateRoute path="/admin/peoples/new/:peopleId?" component={AdminNewPeople} />
            <PrivateRoute path="/admin/peoples/category/new/:peopleCategoryId?" component={AdminNewPeopleCategory} />

            <PrivateRoute exact path="/admin/partners" component={AdminPartners} />
            <PrivateRoute path="/admin/partners/new/:partnerId?" component={AdminNewPartner} />
            <PrivateRoute path="/admin/partners/category/new/:partnerCategoryId?" component={AdminNewPartnerCategory} />

            <PrivateRoute exact path="/admin/messages" component={AdminMessages} />
          </Switch>
        </Main>
        <Footer />
      </Wrapper>
    </Suspense>
  </HashRouter>
);