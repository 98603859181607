import React from "react"
import { Grid } from "@material-ui/core"
import { ThemeProvider } from "@material-ui/core/styles"

import theme from "./theme"
import Routes from "./routes"



const App = () => {
  return (

    <ThemeProvider theme={theme}>
      <Grid item xs={12} disablegutters="true">
        <Routes />
      </Grid>
    </ThemeProvider>
  );
}

export default App;
