import React from "react";
import './styles.scss'
import deinf from './../../assets/deinf.png'
import ufma from './../../assets/ufma.png'
import InstagramIcon from '@mui/icons-material/Instagram';
import { BsInstagram } from "react-icons/bs";
import {ReactComponent as GitLogo} from './../../assets/github.svg'
const Footer = () => {
  return (
    <div className="footer mt-auto py-3  bg-light">
      <div className="w-100 d-flex align-items-center">
        <a target='_BLANK' rel='noopener noreferrer' href='https://sigaa.ufma.br/sigaa/public/departamento/portal.jsf?lc=pt_BR&id=998'>
          <img className='icon' src={deinf} alt='deinf-logo' />
        </a>
        <a target='_BLANK' rel='noopener noreferrer' href='https://portais.ufma.br/PortalUfma/index.jsf'> 
          <img className='icon' src={ufma} alt='ufma-logo' />
        </a>
        <span className="text-muted mx-auto">
          Telemídia-MA
        </span>
        <a target='_BLANK' rel='noopener noreferrer' href='https://www.instagram.com/telemidiaufma/'>
          <BsInstagram className='icon' style={{color: 'black'}}/>
        </a>
        <a target='_BLANK' rel='noopener noreferrer' href='https://github.com/TeleMidia-MA'>
          <GitLogo className='icon' />
        </a>
      </div>
    </div>
  );
};

export default Footer;
